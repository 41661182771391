<template>
  <div class="information-form">
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSe4c9RJVkgTTFCu19wL_rsy4fDYh3CJem5p29_4GAYKvb0vVg/viewform?embedded=true"
      width="640"
      height="750"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      >Loading…</iframe
    >
  </div>
</template>
